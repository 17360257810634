import React from 'react'

import profilePic from '../../profile_@2x.jpg'
import { rhythm } from '../utils/typography'
import presets from '../utils/presets'

class Bio extends React.Component {
  render() {
    return (
      <div
        css={{
          marginBottom: rhythm(1),
          [presets.Tablet]: {
            display: 'flex',
          },
        }}
      >
        <div>
          <p css={{ fontSize: `2rem` }}>Hi, I'm <strong>Chris Feddersen</strong></p>
          <p css={{ fontSize: `1.25rem` }}>I'm a <strong>Lead UI/UX Designer</strong> with over 10 years experience designing, building and coaching. I can help your design team deliver awesome and engaging user experiences for your client's projects
        </p>
        </div>
        <img
          src={profilePic}
          alt={`Chris Feddersen`}
          css={{
            width: rhythm(6),
            height: rhythm(6),
            [presets.Tablet]: {
              marginLeft: rhythm(1 / 2),
            },
          }}
        />
      </div>
    )
  }
}

export default Bio
